<template>
  <div>
    <v-layout class="mt-5 flex-wrap">
      <v-row>
        <v-col
          cols="12"
          md="auto"
          v-for="(label, i) in labels"
          class="caption ms-3"
          :key="i"
        >
          <v-icon x-small left :color="label.color">{{ label.icon }}</v-icon>
          <b> {{ label.name }}</b>
        </v-col>
      </v-row>
    </v-layout>
    <v-alert
      color="var(--v-grey-base)"
      class="mt-5 px-3 font-size-12"
      text
      v-if="!isTherapist"
      dismissible
      icon="mdi-alert"
    >
      {{
        $t(
          "Does your therapist's calendar show no availability? Then reach out to your therapist directly, or contact customer care at"
        )
      }}
      <a href="mailto:customercare@talktime.ae">customercare@talktime.ae</a>
    </v-alert>
  </div>
</template>

<script>
import UserMixin from "@/mixins/UserMixin";

export default {
  name: "CalendarBottomHelp",
  mixins: [UserMixin],

  computed: {
    labels() {
      return [
        {
          name: this.$t("Available"),
          icon: "mdi-checkbox-blank-circle-outline",
          color: "primary",
        },
        // {
        //   name: this.$t("Today"),
        //   icon: "mdi-checkbox-blank-circle",
        //   color: "#ccc",
        // },
        {
          name: this.$t("Booked"),
          icon: "mdi-checkbox-blank-circle",
          color: "secondary",
        },
        // {
        //   name: this.$t("Booked (20min)"),
        //   icon: "mdi-checkbox-blank-circle",
        //   color: "warning",
        // },
        {
          name: this.$t("Canceled"),
          icon: "mdi-checkbox-blank-circle",
          color: "danger",
        },
        {
          name: this.$t("Unsuccessful"),
          icon: "mdi-checkbox-blank-circle-outline",
          color: "danger",
        },
        {
          name: this.$t("Completed"),
          icon: "mdi-checkbox-blank-circle",
          color: "indigo",
        },
      ];
    },
  },
};
</script>

<style scoped></style>
