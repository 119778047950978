<template>
  <v-btn rounded fab small elevation="0" :color="color">
    {{ dayLabel }}
  </v-btn>
</template>

<script>
import UserMixin from "@/mixins/UserMixin";
import moment from "moment";

export default {
  name: "DayLabel",

  mixins: [UserMixin],

  props: {
    events: {
      required: true,
    },
    day: {
      required: true,
    },
  },

  computed: {
    dayLabel() {
      return this.day.day;
    },

    color() {
      if (this.day.past) {
        return "";
      }

      if (this.day.present) {
        return "#ccc";
      } else if (this.currentDayEvents.some((e) => e.status === "booked")) {
        return "secondary";
      } else if (this.currentDayEvents.some((e) => e.status === "available")) {
        return "primary";
      }
      return "transparent";
    },

    currentDayEvents() {
      return this.events.filter((e) => {
        return moment(this.day.date).isSame(e.eventStartDate, "day");
      });
    },
  },
};
</script>
