<template>
  <div>
    Next Session:
    <span class="font-weight-medium ms-2">
      {{ nextBookedEvent.eventStartDate | moment("DD-MMM-YYYY LT") }}
      <v-btn text icon small color="primary" @click="opens = !opens">
        <v-icon small>mdi-pencil</v-icon>
      </v-btn>
    </span>

    <div v-if="opens">
      <v-row class="mt-0 mb-3">
        <v-col cols="7" class="py-0 pr-lg-0">
          <v-btn
            height="42"
            color="secondary"
            class="w-100 font-size-12"
            @click="opens = false"
          >
            {{ nextBookedEvent.eventStartDate | moment("LT") }} -
            {{ nextBookedEvent.eventEndDate | moment("LT") }}
          </v-btn>
        </v-col>

        <v-col cols="5" class="py-0">
          <v-btn
            color="danger"
            outlined
            height="42"
            class="w-100 font-size-12"
            @click="decline(nextBookedEvent)"
          >
            <v-icon small class="me-1 danger--text">mdi-close-circle</v-icon>
            {{ $t("Cancel") }}
          </v-btn>
        </v-col>
      </v-row>
    </div>
  </div>
</template>

<script>
export default {
  name: "ClientNextSession",
  props: {
    nextBookedEvent: {
      required: true,
    },
  },

  data: () => {
    return {
      opens: false,
    };
  },

  methods: {
    decline(event) {
      this.$emit("decline", event);
      this.opens = false;
    },
  },
};
</script>

<style scoped></style>
