<template>
  <v-container v-show="!globalLoading">
    <v-row class="fill-height">
      <v-col cols="12" :md="calendarSize">
        <CreateCalendarEvent
          ref="CreateCalendarEventRef"
          :loading.sync="loading"
          :eventDate="eventDate"
          :currentDateEvents="currentDateEvents"
        />

        <EditCalendarEvent
          ref="EditCalendarEventRef"
          :loading.sync="loading"
          :currentDateEvents="currentDateEvents"
        />

        <!-- Selected Open -->
        <v-menu
          v-model="selectedOpen"
          style="z-index: 100000"
          :close-on-content-click="false"
          offset-x
          :activator="selectedElement"
        >
          <v-card v-if="selectedEvent" color="grey lighten-4" :width="350" flat>
            <!-- <v-toolbar color="primary" elevation="0" dark> -->
            <!--                                <v-btn @click="deleteEvent(selectedEvent.id)" icon>-->
            <!--                                    <v-icon>mdi-delete</v-icon>-->
            <!--                                </v-btn>-->
            <!-- <v-toolbar-title> -->
            <!-- <template v-if="selectedEvent.status === 'available'">
                  Available
                </template>
                <template v-else>
                  {{ getUserName(selectedEventClient) }}
                </template>
              </v-toolbar-title>
              <v-spacer />
              {{ selectedEvent.eventStartDate | moment("DD/MM") }}
            </v-toolbar> -->

            <v-card-text class="pb-0 text-end">
              <strong class="me-1">{{ $t("Start") }}:</strong>
              <span class="me-sm-5">
                {{ selectedEvent.eventStartDate | moment("LT") }}
              </span>

              <strong class="me-1">{{ $t("End") }}:</strong>
              {{ selectedEvent.eventEndDate | moment("LT") }}
            </v-card-text>

            <v-card-actions
              class="flex-wrap pb-5 pe-4 justify-end mt-3"
              v-if="selectedEvent.status === 'available' && !isTherapist"
            >
              <v-btn
                small
                outlined
                color="accent"
                @click.prevent="onBookEventAction(selectedEvent)"
              >
                <v-icon color="accent" left>mdi-check</v-icon>
                {{ $t("Book 50min") }}
              </v-btn>
              <v-btn
                small
                outlined
                color="accent"
                :disabled="!haveAFreeSession"
                @click.prevent="onBookEventActionFree(selectedEvent)"
              >
                <v-icon color="accent" left>mdi-check</v-icon>
                {{ $t("Book free 20min") }}
              </v-btn>
            </v-card-actions>

            <v-card-text v-if="selectedEventClient">
              Booked by
              <userAvatar class="ms-5" :size="20" :user="selectedEventClient" />
              {{ getUserName(selectedEventClient) }}

              <v-divider class="my-4" />
              <div v-if="selectedEvent.status !== 'available'">
                <div v-if="selectedEventTherapist">
                  <userAvatar
                    class="ms-5"
                    :size="20"
                    :user="selectedEventTherapist"
                  />
                  {{ getUserName(selectedEventTherapist) }}
                  <v-icon
                    v-if="
                      selectedEvent.declinedBy.includes(
                        selectedEventTherapist._id
                      )
                    "
                    color="danger"
                  >
                    mdi-account-remove-outline
                  </v-icon>
                  <!--                    <v-icon v-else-if="selectedEvent.acceptedBy.includes(selectedEventTherapist._id)" color="success">-->
                  <!--                      mdi-account-check-outline-->
                  <!--                    </v-icon>-->
                </div>

                <div v-if="selectedEventClient">
                  <userAvatar
                    class="ms-5"
                    :size="20"
                    :user="selectedEventClient"
                  />
                  {{ getUserName(selectedEventClient) }}
                  <v-icon
                    v-if="
                      selectedEvent.declinedBy.includes(selectedEventClient._id)
                    "
                    color="danger"
                  >
                    mdi-account-remove-outline
                  </v-icon>
                  <v-icon
                    v-else-if="
                      selectedEvent.acceptedBy.includes(selectedEventClient._id)
                    "
                    color="success"
                  >
                    mdi-account-check-outline
                  </v-icon>
                </div>
              </div>
            </v-card-text>
            <v-card-actions class="flex-wrap pb-5 pe-4 justify-end mt-3">
              <!--                <v-btn v-if="canAcceptEvent(selectedEvent)" color="success"-->
              <!--                       @click.prevent="acceptEventAction(selectedEvent)">-->
              <!--                  Accept Event-->
              <!--                </v-btn>-->

              <v-btn
                v-if="canDoneEvent(selectedEvent)"
                class="my-2"
                small
                outlined
                color="success"
                @click.prevent="doneEventAction(selectedEvent)"
              >
                <v-icon color="success" left>mdi-check-circle</v-icon>
                {{ $t("Done Session") }}
              </v-btn>

              <v-btn
                v-if="canDeclineEvent(selectedEvent)"
                small
                outlined
                color="danger"
                @click.prevent="declineEventAction(selectedEvent)"
              >
                <v-icon color="danger" left> mdi-close-circle</v-icon>
                {{ $t("Cancel Session") }}
              </v-btn>

              <v-btn
                v-if="canRemoveEvent(selectedEvent)"
                small
                outlined
                color="danger"
                @click.prevent="removeEventAction(selectedEvent)"
              >
                <v-icon color="danger" left> mdi-close-circle</v-icon>
                {{ $t("Cancel Session") }}
              </v-btn>

              <v-btn
                v-if="canEditEvent(selectedEvent)"
                small
                outlined
                color="accent"
                @click.prevent="openEditEventDialog(selectedEvent)"
              >
                <v-icon color="accent" left>mdi-phone-clock</v-icon>
                {{ $t("Reschedule Session") }}
              </v-btn>

              <!--                <v-btn v-if="canBookEvent(selectedEvent)" small outlined color="primary"-->
              <!--                       @click.prevent="bookEventAction(selectedEvent)">-->
              <!--                  {{$t("Book Session")}}-->
              <!--                </v-btn>-->

              <!--                                <v-btn text color="primary" @click="selectedOpen = false">-->
              <!--                                    Close-->
              <!--                                </v-btn>-->
              <!-- <v-btn v-if="currentlyEditing !== selectedEvent.id" text
                  @click.prevent="editEvent(selectedEvent)">
                  edit
              </v-btn> -->
              <!--                                <v-btn text type="submit" @click.prevent="updateEvent(selectedEvent)">-->
              <!--                                    Save-->
              <!--                                </v-btn>-->
            </v-card-actions>
          </v-card>
        </v-menu>

        <CalendarView
          :rightSideBar="rightSideBar"
          :type="type"
          :eventsShow="eventsShow"
          :getEventClient="getEventClient"
          @viewDay="viewDay"
          @showEvent="showEvent"
        />
      </v-col>

      <CalendarClientRightSide
        v-if="isClient && rightSideBar"
        :loading.sync="loading"
        :clientUser="me"
        :nextBookedEvent="nextBookedEvent"
        :currentDateEvents="currentDateEvents"
        @bookEventAction="onBookEventAction"
        @bookEventActionFree="onBookEventActionFree"
        @declineEventAction="declineEventAction"
      />

      <CalendarTherapistRightSide
        v-if="isTherapist"
        :therapistUser="me"
        :activeClientsCount="myActiveClients.length"
        :availableEvents="availableEvents"
        :bookedEvents="bookedEvents"
        :nextBookedEvent="nextBookedEvent"
        :eventDate="eventDate"
        :currentDateEvents="currentDateEvents"
        @showEvent="showEvent"
        @openCreateEventDialog="openCreateEventDialog"
      />
    </v-row>

    <v-dialog v-model="cancelDialog" max-width="500px">
      <v-card>
        <!-- <v-toolbar color="primary" class="white--text text-h5">Cancel Live Session</v-toolbar> -->
        <v-btn
          @click="cancelDialog = !cancelDialog"
          elevation="2"
          icon
          class="close-icon pa-0"
        >
          <v-icon>mdi-close</v-icon>
        </v-btn>
        <v-card-text class="pt-5">
          <p class="text-center font-weight-bold" v-if="!isTherapist">
            {{
              $t(
                "Since the scheduling of an appointment involves the reservation of time set aside especially for you, a minimum of 12 hours notice is required for rescheduling or cancellation of an appointment.If a session is canceled less than 12 hours prior, 1 Live Session credit will be charged."
              )
            }}
          </p>
          <p class="text-center font-weight-bold" v-if="isTherapist">
            {{
              $t(
                "A penalty may be apply if a booked session is cancelled within 12 hours prior to session time."
              )
            }}
          </p>
          <p class="text-center font-weight-bold">
            {{ $t("Are you sure you want to cancel the live session?") }}
          </p>
        </v-card-text>
        <v-card-actions class="pb-5">
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            class="px-15 mx-2"
            outlined
            large
            @click="cancelDialogCancel"
          >
            {{ $t("No") }}
          </v-btn>
          <v-btn
            color="primary"
            class="px-15 mx-2"
            large
            @click="cancelDialogAccept"
          >
            {{ $t("Yes") }}
          </v-btn>
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
// import ClientEvent from "@/components/Calendar/ClientEvent";
import { mapActions, mapGetters } from "vuex";
import UserMixin from "@/mixins/UserMixin";
import UserAvatar from "@/components/UserAvatar";
import CreateCalendarEvent from "@/components/Calendar/CreateCalendarEvent";
import EditCalendarEvent from "@/components/Calendar/EditCalendarEvent";
import CalendarTherapistRightSide from "@/components/Calendar/CalendarTherapistRightSide";
import CalendarClientRightSide from "@/components/Calendar/CalendarClientRightSide";
import CalendarView from "@/components/Calendar/Calendar";
import therapistMixin from "@/mixins/TherapistMixin";
const moment = require("moment");

export default {
  name: "Calendar",
  components: {
    // ClientEvent,
    UserAvatar,
    CreateCalendarEvent,
    CalendarTherapistRightSide,
    CalendarClientRightSide,
    CalendarView,
    EditCalendarEvent,
  },
  props: {
    rightSideBar: {
      type: Boolean,
      default: true,
    },
    type: {
      type: String,
      default: () => "month",
    },
  },
  mixins: [UserMixin, therapistMixin],
  data: () => ({
    cancelDialog: false,
    cancelDialogCallback: null,

    model: null,
    // eventName: null,

    //--// eventStartTime: null,
    // startTimeDialog: false,

    // eventEndTime: null,
    eventDate: null,

    endTimeMenu: false,
    // currentlyEditing: null,
    selectedEvent: null,
    selectedElement: null,
    selectedOpen: false,
    // bookedOpen: false,
    dialogDate: false,
    loading: false,
    // addLiveSession: false,
    // numberOfLiveSessions: 1,
  }),
  computed: {
    ...mapGetters("calendar", ["events"]),

    calendarSize() {
      if (this.isClient && !this.rightSideBar) {
        return 12;
      }
      if (this.isClient && this.rightSideBar) {
        return 7;
      }

      return 8;
    },

    selectedEventClient() {
      if (this?.selectedEvent?.client) {
        if (this.isTherapist)
          return this.getClientById(this.selectedEvent.client);
        return this.me;
      }
      return null;
    },

    selectedEventTherapist() {
      if (this?.selectedEvent?.therapist) {
        if (this.isTherapist) return this.me;
        return this.myTherapist;
      }
      return null;
    },

    eventsShow() {
      if (this.loading) {
        return [];
      }
      let events = JSON.parse(JSON.stringify(this.events));
      events.forEach((e) => {
        e.eventStartDate = new Date(e.eventStartDate);
        e.eventEndDate = new Date(e.eventEndDate);
        e.timed = true;
      });
      return events;
    },

    eventsFromToday() {
      return this.events.filter((e) =>
        moment(e.eventStartDate).isAfter(moment())
      );
    },

    availableEvents() {
      return this.eventsFromToday.filter((e) => e.status === "available");
    },

    bookedEvents() {
      return this.eventsFromToday.filter((e) =>
        ["booked", "bookedFree"].includes(e.status)
      );
    },

    nextBookedEvent() {
      return (
        [...this.bookedEvents].sort(
          (a, b) => moment(a.eventStartDate) - moment(b.eventStartDate)
        )[0] || null
      );
    },

    currentDateEvents() {
      if (!this.eventDate) {
        return [];
      }
      let currentDateEvents = this.findEventsByDate(this.eventDate);
      currentDateEvents.sort((a, b) =>
        moment(a.eventStartDate - moment(b.eventStartDate))
      );

      return currentDateEvents;
    },

    haveAFreeSession() {
      return this.me?.metadata?.numberFreeLiveSessionsHave > 0;
    },
  },

  methods: {
    getEventClient(event) {
      if (event.client) {
        if (this.isTherapist) return this.getClientById(event.client);
        return this.me;
      }
      return null;
    },

    openCreateEventDialog() {
      // if (this.isTherapist) {
      this.$refs.CreateCalendarEventRef.open();
      this.selectedOpen = false;
      // this.startTimeDialog = true;
      // }
    },

    openEditEventDialog(ev) {
      // if (this.isTherapist) {
      this.eventDate = moment(ev.eventStartDate).format("YYYY-MM-DD");

      this.$refs.EditCalendarEventRef.open(ev);
      this.selectedOpen = false;
      // this.startTimeDialog = true;
      // }
    },

    viewDay({ date }) {
      this.eventDate = date;
    },

    ...mapActions("calendar", [
      "getEvents",
      // "createEvent",
      "updateEvent",
      "removeEvent",
      "bookEvent",
      "bookFreeEvent",
      "acceptEvent",
      "declineEvent",
      "doneEvent",
    ]),

    async onBookEventAction(event) {
      await this.bookEventAction(event);
    },

    async onBookEventActionFree(event) {
      await this.bookFreeEventAction(event);
    },

    cancelDialogCancel() {
      this.cancelDialog = false;
      this.cancelDialogCallback = null;
    },

    async cancelDialogAccept() {
      if (typeof this.cancelDialogCallback === "function") {
        await this.cancelDialogCallback();
      }
      this.cancelDialog = false;
    },

    canBookEvent(event) {
      // TODO: Add can book if have availability
      return (
        !this.isTherapist &&
        event.status === "available" &&
        (this.me?.metadata?.numberLiveSessionsHave > 0 ||
          this.me?.metadata?.numberCorporateLiveSessionsHave > 0 ||
          this.me?.metadata?.numberFreeLiveSessionsHave > 0)
      );
    },

    canEditEvent(event) {
      return (
        this.isTherapist &&
        ["booked", "bookedFree", "available"].includes(event.status)
      );
    },

    canRemoveEvent(event) {
      // TODO: Add can book if have availability
      return event.status === "available" && this.isTherapist;
    },

    canAcceptEvent(event) {
      return (
        event.status === "booked" && !event.acceptedBy.includes(this.me._id)
      );
    },

    canDoneEvent(event) {
      return (
        this.isTherapist &&
        ["booked", "bookedFree"].includes(event.status) &&
        moment().isAfter(event.eventEndDate)
      );
    },

    canDeclineEvent(event) {
      // TODO ADD DECLINE MODAL IF LESS 12 HOURS
      return (
        ["booked", "bookedFree"].includes(event.status) &&
        !event.declinedBy.includes(this.me._id)
      );
    },

    findEventsByDate(date) {
      return this.eventsShow.filter((e) => {
        return moment(date).isSame(e.eventStartDate, "day");
      });
    },

    async doneEventAction(event) {
      this.loading = true;

      await this.doneEvent({ id: event._id }).catch((err) => {
        this.addNotification({
          message: err.response.data.message,
          icon: "mdi-alert",
          timeout: 3000,
        });
      });

      await this.getEvents();

      this.loading = false;
      this.selectedOpen = false;
      this.selectedElement = null;
      this.selectedEvent = null;
    },

    async bookFreeEventAction(event) {
      this.loading = true;

      await this.bookFreeEvent({ id: event._id }).catch((err) => {
        this.addNotification({
          message: err.response.data.message,
          icon: "mdi-alert",
          timeout: 3000,
        });
      });

      await this.getMe();

      this.loading = false;
      this.selectedOpen = false;
      this.selectedElement = null;
      this.selectedEvent = null;
    },

    async bookEventAction(event) {
      this.loading = true;

      await this.bookEvent({ id: event._id }).catch((err) => {
        this.addNotification({
          message: err.response.data.message,
          icon: "mdi-alert",
          timeout: 3000,
        });
      });

      await this.getMe();

      this.loading = false;
      this.selectedOpen = false;
      this.selectedElement = null;
      this.selectedEvent = null;
    },

    async removeEventAction(event) {
      this.loading = true;
      await this.removeEvent({ id: event._id });
      this.loading = false;
      this.selectedOpen = false;
      this.selectedElement = null;
      this.selectedEvent = null;
    },

    async acceptEventAction(event) {
      this.loading = true;
      await this.acceptEvent({ id: event._id });
      this.loading = false;
      this.selectedOpen = false;
      this.selectedElement = null;
      this.selectedEvent = null;
    },

    async declineEventAction(event) {
      if (moment(event.eventStartDate).diff(moment(), "hours") < 12) {
        this.cancelDialogCallback = async () => {
          await this.onDeclineEventAction(event);
        };
        this.cancelDialog = true;
        this.selectedOpen = false;
      } else {
        await this.onDeclineEventAction(event);
      }
    },

    async onDeclineEventAction(event) {
      this.loading = true;
      await this.declineEvent({ id: event._id });
      this.loading = false;
      this.selectedOpen = false;
      this.selectedElement = null;
      this.selectedEvent = null;
    },
    // editEvent(ev) {
    //     this.currentlyEditing = ev.id
    // },
    showEvent({ nativeEvent, event }) {
      const open = () => {
        this.selectedEvent = event;
        this.selectedElement = nativeEvent.target;
        this.$nextTick(() => (this.selectedOpen = true));
        // if (event.status === 'available') {
        //   this.selectedEvent = event
        //   this.selectedElement = nativeEvent.target
        //   this.$nextTick(() => this.selectedOpen = true)
        // }
        // else if (event.status === 'booked') {
        //     this.selectedEvent = event
        //     this.selectedElement = nativeEvent.target
        //     this.$nextTick(() => this.booked = true)
        // }
        // else if (event.status === 'declined') {
        //     this.selectedEvent = event
        //     this.selectedElement = nativeEvent.target
        //     this.$nextTick(() => this.declined = true)
        // }
        // setTimeout(() => this.selectedOpen = true, 10)
      };
      if (this.selectedOpen) {
        this.selectedOpen = false;
        this.$nextTick(open);
        // setTimeout(open, 10)
      } else {
        open();
      }

      nativeEvent.stopPropagation();
    },

    // declineEventAction(event) {
    //   this.$emit('declineEventAction', event)
    // },

    // bookEventActionFree(event) {
    //   this.$emit('bookEventActionFree', event)
    // }
    // ,
    // bookEventAction(event) {
    //   this.$emit('bookEventAction', event)
    // },
  },

  mounted() {
    this.awaitPromiseCallback({
      key: "getMe",
      cb: async () => {
        try {
          await this.getEvents();
        } catch (e) {
          console.log("e: ", e);
        }
      },
    });
  },
};
</script>

<style lang="scss">
.v-calendar-daily__scroll-area {
  overflow-y: auto !important;
}

.v-calendar-daily__day v-future:last-child {
  border-radius: 10px;
}
</style>
