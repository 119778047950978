<template>
  <div v-if="event.status !== 'declined'">
    <v-btn
      small
      block
      :outlined="event.status === 'available'"
      :color="color"
      class="mb-3 py-5"
      :dark="event.status === 'done'"
      :class="{ 'text-decoration-line-through': notAccessibleEvent }"
      elevation="0"
      @click="onClick"
    >
      {{ event.eventStartDate | moment("LT") }} -
      {{ event.eventEndDate | moment("LT") }}

      <template v-if="event.status === 'booked'">
        {{ $t("50min") }}
      </template>
      <template v-if="event.status === 'bookedFree'">
        {{ $t("20min") }}
      </template>
    </v-btn>
  </div>
</template>

<script>
import moment from "moment";

export default {
  name: "TherapistEvent",
  props: {
    event: {
      required: true,
    },
  },

  computed: {
    color() {
      if (this.event.status === "available") {
        return "primary";
      } else if (this.event.status === "booked") {
        return "secondary";
      } else if (this.event.status === "bookedFree") {
        return "secondary";
      } else if (this.event.status === "done") {
        return "indigo";
      } else if (this.event.status === "unsuccessful") {
        return "error";
      }

      return "";
    },

    notAccessibleEvent() {
      return (
        !["booked", "bookedFree", "done", "unsuccessful"].includes(
          this.event.status
        ) &&
        moment(this.event.eventStartDate)
          .subtract(3, "hours")
          .isBefore(moment())
      );
    },
  },

  methods: {
    onClick(nativeEvent) {
      this.$emit("open", { nativeEvent, event: this.event });
    },
  },
};
</script>

<style scoped></style>
