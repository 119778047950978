<template>
  <div>
    <v-toolbar
      flat
      class="calendarToolbar"
      :class="isClient ? 'client-toolbar' : ''"
      rounded="lg"
      outlined
    >
      <v-btn outlined color="accent" :small="isClient" @click="setToday">
        {{ $t("Today") }}
      </v-btn>

      <v-btn fab text small outlined @click="prev" class="ms-auto me-2">
        <v-icon small
          >{{ $vuetify.rtl ? "mdi-chevron-right" : "mdi-chevron-left" }}
        </v-icon>
      </v-btn>

      <v-toolbar-title class="mx-3">{{ title }}</v-toolbar-title>

      <v-btn fab text small outlined @click="next" class="ms-2">
        <v-icon small
          >{{ $vuetify.rtl ? "mdi-chevron-left" : "mdi-chevron-right" }}
        </v-icon>
      </v-btn>

      <v-menu bottom right offset-y>
        <template v-slot:activator="{ on }">
          <v-btn
            outlined
            color="accent"
            :class="isClient ? 'd-none' : ''"
            class="ms-auto"
            v-on="on"
          >
            <span>{{ $t(typeToLabel[type]) }}</span>
            <v-icon right>mdi-menu-down</v-icon>
          </v-btn>
        </template>
        <v-list dense>
          <v-list-item @click="type = 'day'">
            <v-list-item-title>{{ $t("Day") }}</v-list-item-title>
          </v-list-item>
          <v-list-item @click="type = 'week'">
            <v-list-item-title>{{ $t("Week") }}</v-list-item-title>
          </v-list-item>
          <v-list-item @click="type = 'month'">
            <v-list-item-title>{{ $t("Month") }}</v-list-item-title>
          </v-list-item>
          <v-list-item @click="type = '4day'">
            <v-list-item-title>{{ $t("4 Days") }}</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
    </v-toolbar>

    <v-sheet
      height="600"
      class="bg-transparent mt-10 rounded-lg"
      :class="isClient ? 'calendar-client-view' : ''"
    >
      <!--          :event-timed=""-->

      <v-calendar
        :locale="$vuetify.rtl ? 'ar' : 'en'"
        ref="calendar"
        v-model="focus"
        color="primary"
        event-start="eventStartDate"
        event-end="eventEndDate"
        :events="eventsShow"
        :event-margin-bottom="3"
        :now="today"
        :type="type"
        @click:event="showEvent"
        @click:more="viewDayInside"
        @click:date="viewDay"
        @click:day="viewDay"
        @change="updateRange"
        rounded="lg"
      >
        <template v-slot:day-label="day">
          <DayLabel :events="eventsShow" :day="day" />
        </template>

        <template v-slot:event="{ event }">
          <div
            v-if="event.status === 'available'"
            class="event available-event"
          >
            {{ event.eventStartDate | moment("LT") }}
            <!--                Available-->
          </div>
          <div v-else-if="event.status === 'booked'" class="event booked-event">
            <template v-if="isTherapist">
              <span class="client-name">{{
                getUserName(getEventClient(event))
              }}</span>
              <span class="duration">{{ $t("50min") }}</span>
            </template>
            <template v-if="!isTherapist">
              {{ $t("50min") }}
            </template>
          </div>
          <div
            v-else-if="event.status === 'bookedFree'"
            class="event booked-event"
          >
            <template v-if="isTherapist">
              <span class="client-name">{{
                getUserName(getEventClient(event))
              }}</span>
              <span class="duration">{{ $t("20min") }}</span>
            </template>
            <template v-if="!isTherapist">
              {{ $t("20min") }}
            </template>
          </div>
          <div v-else-if="event.status === 'done'" class="event done-event">
            {{ getUserName(getEventClient(event)) }}
          </div>
          <div
            v-else-if="event.status === 'declined'"
            class="event declined-event"
          >
            <template v-if="isTherapist">
              <span class="client-name">{{
                getUserName(getEventClient(event))
              }}</span>
              <span>{{ $t("Canceled") }}</span>
            </template>
            <template v-if="!isTherapist">
              {{ $t("Canceled") }}
            </template>
          </div>
          <div
            v-else-if="event.status === 'unsuccessful'"
            class="event not-joined-event"
          >
            {{ $t("Unsuccessful") }}
          </div>
        </template>
      </v-calendar>

      <CreditStatusOption ref="CreditStatusOptionRef" :getEventClient="getEventClient" />

      <!-- Booked Open -->
      <!-- <v-menu v-model="bookedOpen" :close-on-content-click="false" :activator="selectedElement" offset-x>
          <v-card color="grey lighten-4"  :width="350" flat>
              <v-toolbar :color="selectedEvent.color" dark>
                  <v-btn @click="deleteEvent(selectedEvent.id)" icon>
                      <v-icon>mdi-delete</v-icon>
                  </v-btn>
                  <v-toolbar-title v-html="selectedEvent.eventName"></v-toolbar-title>
                  <div class="flex-grow-1"></div>
              </v-toolbar>
              <v-card-text>
                  <form v-else>
                      <textarea-autosize v-model="selectedEvent.details" type="text" style="width: 100%"
                          :min-height="100" placeholder="add note">
                      </textarea-autosize>
                  </form>
              </v-card-text>
              <v-card-actions>
                  <v-btn text color="secondary" @click="bookedOpen = false">
                      close
                  </v-btn>
                  <v-btn v-if="currentlyEditing !== selectedEvent.id" text
                      @click.prevent="editEvent(selectedEvent)">
                      edit
                  </v-btn>
                  <v-btn text v-else type="submit" @click.prevent="updateEvent(selectedEvent)">
                      Booking
                  </v-btn>
              </v-card-actions>
          </v-card>
      </v-menu> -->
    </v-sheet>

    <CalendarBottomHelp />
  </div>
</template>

<script>
import UserMixin from "@/mixins/UserMixin";
import CalendarBottomHelp from "@/components/Calendar/CalendarBottomHelp";
import DayLabel from "@/components/Calendar/DayLabel";
import CreditStatusOption from "@/components/Calendar/CreditStatusOption.vue";

export default {
  name: "CalendarComponent",
  components: {
    CreditStatusOption,
    CalendarBottomHelp,
    DayLabel,
  },

  mixins: [UserMixin],

  props: {
    eventsShow: {
      required: true,
    },

    getEventClient: {
      required: true,
    },

    type: {
      required: true,
    },

    rightSideBar: {
      required: true,
    },
  },

  data: () => {
    return {
      typeToLabel: {
        month: "Month",
        week: "Week",
        day: "Day",
        "4day": "4 Days",
      },

      start: null,
      end: null,

      today: new Date().toISOString().substr(0, 10),
      focus: new Date().toISOString().substr(0, 10),
    };
  },

  computed: {
    title() {
      const { start, end } = this;
      if (!start || !end) {
        return "";
      }
      const startMonth = this.monthFormatter(start);
      const endMonth = this.monthFormatter(end);
      const suffixMonth = startMonth === endMonth ? "" : endMonth;
      const startYear = start.year;
      const endYear = end.year;
      const suffixYear = startYear === endYear ? "" : endYear;
      const startDay = start.day + this.nth(start.day);
      const endDay = end.day + this.nth(end.day);
      switch (this.type) {
        case "month":
          return `${startMonth} ${startYear}`;
        case "week":
        case "4day":
          return `${startMonth} ${startDay} ${startYear} - ${suffixMonth} ${endDay} ${suffixYear}`;
        case "day":
          return `${startMonth} ${startDay} ${startYear}`;
      }
      return "";
    },

    monthFormatter() {
      return this.$refs.calendar.getFormatter({
        timeZone: "UTC",
        month: "long",
      });
    },
  },

  methods: {
    prev() {
      this.$refs.calendar.prev();
    },

    next() {
      this.$refs.calendar.next();
    },

    setToday() {
      this.focus = this.today;
    },

    viewDay({ date }) {
      this.focus = date;
      this.$emit("viewDay", { date });
    },

    viewDayInside({ date }) {
      this.viewDay({ date });
      this.type = "day";
    },

    updateRange({ start, end }) {
      this.start = start;
      this.end = end;
    },

    showEvent(data) {
      this.$emit("showEvent", data);
    },

    nth(d) {
      return d > 3 && d < 21
        ? "th"
        : ["th", "st", "nd", "rd", "th", "th", "th", "th", "th", "th"][d % 10];
    },
  },

  watch: {
    eventsShow() {
      if (this.isTherapist) {
        for(let event of this.eventsShow) {
          if(event?.therapistActionRequired) {
            this.$refs.CreditStatusOptionRef.open(event);
            break
          }
        }
      }
    }
  },

  mounted() {
    // if(this.isTherapist) {
      // this.$refs.CreditStatusOptionRef.open();
    // }

  }
};
</script>

<style scoped lang="scss">
.v-calendar::v-deep {
  .v-event {
    height: auto !important;
    .client-name {
      display: block;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
    .duration {
      display: block;
    }
  }
}

.theme--light.v-calendar-daily::v-deep {
  border: 1px solid #e0e0e0;
  .v-calendar-daily__day {
    border-bottom: none !important;
  }
}
</style>
