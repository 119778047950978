<template>
  <v-col cols="12" sm="4">
    <v-card
      rounded="lg"
      color="accent lighten-2"
      outlined
      elevation="0"
      class="mb-3"
    >
      <v-card-text>
        <template>
          <p class="dark--text font-weight-medium">
            {{ $t("MonthlyCapacity") }}
            <strong class="font-weight-normal">
              {{ capacity }}
            </strong>
          </p>
          <p class="dark--text font-weight-medium">
            {{ $t("ActiveClients") }}
            <strong class="font-weight-normal">
              {{ activeClientsCount }}
            </strong>
          </p>
          <p class="dark--text font-weight-medium">
            {{ $t("AvailableSessions") }}
            <strong class="font-weight-normal">
              {{ availableEvents.length }}
            </strong>
          </p>
          <p class="dark--text font-weight-medium">
            {{ $t("BookedSessions") }}
            <strong class="font-weight-normal">
              {{ bookedEvents.length }}
            </strong>
          </p>
        </template>
      </v-card-text>
    </v-card>
    <v-card rounded="lg" outlined class="" elevation="0">
      <v-card-text>
        <p class="subtitle-2 mb-1 font-weight-light" v-if="nextBookedEvent">
          Next Session:
          <span class="font-weight-medium ms-2">
            {{ nextBookedEvent.eventStartDate | moment("DD-MMM-YYYY LT") }}
            <v-btn
              text
              icon
              small
              color="primary"
              @click="
                showEvent({ nativeEvent: $event, event: nextBookedEvent })
              "
            >
              <v-icon small>mdi-pencil</v-icon>
            </v-btn>
          </span>
          With
          <router-link
            :to="{
              name: 'PatientProfile',
              params: { id: nextBookedEvent.client },
            }"
          >
            {{ getUserName(getClientById(nextBookedEvent.client)) }}
          </router-link>
        </p>
        <p class="subtitle-2 mb-3 font-weight-medium">
          {{ $t("LiveSessionsAvailable") }}:
        </p>

        <v-btn
          v-if="canAddLiveSession"
          color="accent"
          class="py-4 font-weight-bold"
          outlined
          block
          small
          @click="openCreateEventDialog"
        >
          <v-icon small left>mdi-plus-circle-outline</v-icon>
          {{ $t("AddLiveSession") }}
        </v-btn>
        <v-chip disabled small class="" v-else>
          To create a new live session, please select a day first.
        </v-chip>

        <!-- <template v-if="currentDateEvents.length">
          <div class="booked-sessions mt-5">
            <p class="text-center"><b>Booked Sessions</b></p>
            <div class="v-event mt-3" v-for="event in currentDateEvents" :key="event._id">
              <template v-if="event.status === 'booked'">
                <v-btn small block elevation="0" class="event booked-event"
                  @click="showEvent({ nativeEvent: $event, event })">
                  {{ event.eventStartDate | moment("LT") }} -
                  {{ event.eventEndDate | moment("LT") }}
                </v-btn>
              </template>
            </div>
          </div>

          <div class="available-sessions mt-10" v-if="isTherapist">
            <p class="text-center"><b>Available Sessions</b></p>
            <div class="v-event mt-3" v-for="event in currentDateEvents" :key="event._id">
              <template v-if="event.status === 'available'">
                <v-btn small block elevation="0" class="event available-event"
                  @click="showEvent({ nativeEvent: $event, event })">
                  {{ event.eventStartDate | moment("LT") }} -
                  {{ event.eventEndDate | moment("LT") }}
                </v-btn>
              </template>
            </div>
          </div>
        </template> -->

        <!-- TODO fix for therapist -->
        <template v-for="event in currentDateEvents">
          <TherapistEvent
            :key="event._id + 'et'"
            :event="event"
            class="mt-2"
            @open="showEvent"
          />
        </template>
      </v-card-text>
    </v-card>
  </v-col>
</template>

<script>
import UserMixin from "@/mixins/UserMixin";
import TherapistEvent from "@/components/Calendar/TherapistEvent";
import moment from "moment";

export default {
  name: "CalendarTherapistRightSide",
  components: {
    TherapistEvent,
  },
  mixins: [UserMixin],

  props: {
    therapistUser: {
      required: true,
    },

    activeClientsCount: {
      required: true,
    },

    availableEvents: {
      required: true,
    },

    bookedEvents: {
      required: true,
    },

    eventDate: {
      required: true,
    },

    currentDateEvents: {
      required: true,
    },

    nextBookedEvent: {
      required: true,
    },
  },

  computed: {
    capacity() {
      return this.therapistUser?.therapistInfo?.capacity || 0;
    },

    canAddLiveSession() {
      return !!(
        // this.isTherapist &&
        moment(this.eventDate).isSameOrAfter(moment(), "day")
      );
    },
  },

  methods: {
    showEvent(data) {
      this.$emit("showEvent", data);
    },

    openCreateEventDialog() {
      this.$emit("openCreateEventDialog");
    },
  },
};
</script>

<style scoped></style>
