<template>
  <v-dialog
    ref="startTimeDialog"
    v-model="dialog"
    :close-on-content-click="false"
    :nudge-right="40"
    :return-value.sync="eventStartTime"
    transition="scale-transition"
    offset-y
    max-width="290px"
    min-width="290px"
  >
    <!-- <template v-slot:activator="{ on, attrs }">
              <v-text-field v-model="eventStartTime" label="Event Start Time"
                append-icon="mdi-clock-time-four-outline" readonly :disabled="loading" :loading="loading"
                v-bind="attrs" v-on="on">
              </v-text-field>

            </template> -->

    <v-time-picker
      header-color="transparent"
      ampm-in-title
      v-if="dialog"
      :disabled="loading"
      :loading="loading"
      ref="startTimePickerRef"
      :allowed-minutes="allowedMinutes"
      :allowed-hours="allowedHours"
      v-model="eventStartTime"
    >
      <v-btn outlined color="accent" @click="editEvent" class="ms-auto">
        {{ $t("Ok") }}
      </v-btn>
      <v-btn text color="accent" @click="close" class="me-auto">
        {{ $t("Cancel") }}
      </v-btn>
    </v-time-picker>
    <!-- <v-btn text color="primary" type="submit">
        OK
      </v-btn> -->
  </v-dialog>
</template>

<script>
import moment from "moment";
import { mapActions } from "vuex";

export default {
  name: "EditCalendarEvent",

  props: {
    loading: {
      required: false,
      default: false,
    },

    currentDateEvents: {
      required: true,
    },
  },

  data: () => {
    return {
      eventStartTime: null,
      event: null,
      dialog: false,
    };
  },

  methods: {
    ...mapActions("calendar", ["updateEvent"]),

    open(editedEvent) {
      this.dialog = true;
      this.event = { ...editedEvent };
      let date = moment(this.event.eventStartDate);
      this.eventDate = date.format("YYYY-MM-DD");
      this.eventStartTime = date.hour() + ":" + date.minute();
    },

    close() {
      this.dialog = false;
      this.event = null;
      this.eventStartTime = null;
    },

    async editEvent() {
      if (this.eventStartTime) {
        // this.eventName = this.eventStartTime;
        let startDate = moment(this.event.eventStartDate);
        let startTimeArr = this.eventStartTime.split(":");
        startDate.hour(startTimeArr[0]);
        startDate.minute(startTimeArr[1]);
        let endDate = startDate.clone();
        endDate.add(50, "minutes");
        // let endDate = moment(this.eventDate);
        // let endTimeArr = this.eventEndTime.split(':');
        // endDate.hour(endTimeArr[0])
        // endDate.minute(endTimeArr[1])
        const updateData = {
          // eventName: this.eventName,
          eventStartDate: startDate.toISOString(),
          eventEndDate: endDate.toISOString(),
        };

        this.$emit("update:loading", true);

        await this.updateEvent({
          id: this.event._id,
          updateData,
        });

        this.$emit("update:loading", false);

        this.close();
        // this.events.push(tmp)
        // this.dialog = false;
        // this.eventName = "";
        // this.eventStartTime = "";
        // this.eventEndTime = '';
        // this.eventDate = "";
      } else {
        alert("Please enter event start date");
      }
    },

    allowedMinutes(minute) {
      let mHourDate = moment(this.eventDate);
      mHourDate.hours(this.$refs.startTimePickerRef.inputHour);
      mHourDate.minutes(minute);

      // if (!mHourDate.isAfter(moment().add(3, "hours"), "minute")) {
      //   return false;
      // }

      if (this.currentDateEvents.length) {
        for (let e of this.currentDateEvents) {
          if (e.status === "declined" || e._id === this.event._id) {
            continue;
          }
          let startD = moment(e.eventStartDate).subtract(60, "minutes");
          let endD = moment(e.eventEndDate).add(20, "minutes");
          if (mHourDate.isBetween(startD, endD)) {
            return false;
          }
        }
      }
      return minute % 5 === 0;
    },

    allowedHours(hour) {
      let mHourDate = moment(this.eventDate);
      mHourDate.hours(hour);

      // if (!mHourDate.isAfter(moment().add(2, "hours"), "hour")) {
      //   return false;
      // }

      if (this.currentDateEvents.length) {
        for (let e of this.currentDateEvents) {
          if (e.status === "declined" || e._id === this.event._id) {
            continue;
          }

          let startD = moment(e.eventStartDate).subtract(60, "minutes");
          let endD = moment(e.eventEndDate).add(20, "minutes");
          if (mHourDate.isBetween(startD, endD)) {
            return false;
          }
        }
      }
      return true;
    },
  },
};
</script>

<style scoped></style>
