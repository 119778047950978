<template>
  <v-dialog v-model="dialog" persistent width="700">
    <v-card class="rounded-lg">
      <v-btn elevation="2" icon class="close-icon pa-0" @click="dialog = false">
        <v-icon>mdi-close</v-icon>
      </v-btn>

      <v-card-text class="pa-8">
        <p class="text-center mb-0">
          Your client <strong>{{ clientName }}</strong> cancelled a session that was booked for <strong>{{ dateAndTime }}</strong>. <br/>
          In this case, you can choose to either refund the session to the client (and therefore you will not be compensated for the cancellation) or charge them for the session.
        </p>

      </v-card-text>

      <v-card-actions class="pb-6">
        <v-spacer></v-spacer>
        <v-btn color="primary" class="px-8 me-3" large @click="refund">
          {{ $t("Refund session credit") }}
        </v-btn>
        <v-btn color="primary" outlined class="px-8" @click="charge" large>
          {{ $t("Charge session credit") }}
        </v-btn>
        <v-spacer></v-spacer>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>



import {mapActions} from "vuex";
import UserMixin from "@/mixins/UserMixin";
import moment from "moment/moment";

export default {
  name: "CreditStatusOption",

  mixins: [UserMixin],

  props: {
    getEventClient: {
      required: true,
    },
  },

  data: () => {
    return {
      event: null,
      dialog: false,
    }
  },

  computed: {
    clientName() {
      if(!this.event) {
        return ''
      }
      return this.getUserName(this.getEventClient(this.event)) || ''
    },

    dateAndTime() {
      if(!this.event) {
        return ''
      }
      return moment(this.event.eventStartDate).format("L LT");
    }
  },

  methods: {

    ...mapActions('calendar', ['actionFromTherapist']),

    open(event) {
      this.event = event;
      this.dialog = true;
    },

    close() {
      this.event = null;
      this.dialog = false;
    },

    async refund() {
      await this.actionFromTherapist({id: this.event._id, data: {refund: true}});
      this.close();
    },

    async charge() {
      await this.actionFromTherapist({id: this.event._id, data: {refund: false}});
      this.close();
    }
  }
}
</script>
