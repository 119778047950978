<template>
  <v-col>
    <v-card rounded="lg" outlined class="pa-5 mb-5" elevation="0">
      <p class="dark--text font-weight-medium">
        {{ $t("Live Session Credits:") }}
        <strong class="font-weight-normal">
          {{ liveSessionCount }}
        </strong>
      </p>
      <p class="dark--text font-weight-medium">
        {{ $t("Free session credit") }}:
        <strong class="font-weight-normal">
          {{ liveFreeSessionCount }}
        </strong>
      </p>
      <v-btn
        color="accent"
        class="mt-3 mb-5 py-5 body-2 font-weight-bold"
        outlined
        small
        block
        :loading="loading"
        :disabled="loading"
        @click="onAddLiveSessionAction"
      >
        <v-icon left>mdi-plus-circle-outline</v-icon>
        {{ $t("Buy additional Live Session credits") }}
      </v-btn>

      <AddLiveSession
        :addLiveSession.sync="addLiveSession"
        :loading="loading"
        @update:loading="
          (val) => {
            $emit('update:loading', val);
          }
        "
      />
    </v-card>

    <v-card
      rounded="lg"
      outlined
      class="pa-5 overflow-y-auto"
      style="max-height: calc(100vh - 100px)"
      elevation="0"
    >
      <p class="subtitle-2 mb-1 font-weight-light" v-if="nextBookedEvent">
        <!-- Next Session -->
        <ClientNextSession
          :nextBookedEvent="nextBookedEvent"
          @decline="declineEventAction"
        />
      </p>
      <span v-else>
        <v-alert text outlined color="primary" class="font-size-12" dense>
          {{ $t("NoBookedSessions") }}
        </v-alert>
      </span>
      <p class="subtitle-2 mb-1 font-weight-medium">
        {{ $t("LiveSessionAvailable") }}
      </p>
      <!-- <v-alert text color="primary" class="font-size-12" dense>
        Date Selected: {{focus}}
      </v-alert> -->

      <v-card-text class="text-center px-0" v-if="currentDateEvents.length > 0">
        <!-- <v-btn class="mt-3">
          {{ event.eventStartDate | moment("LT") }} -
          {{ event.eventEndDate | moment("LT") }}
        </v-btn> -->

        <template v-for="event in currentDateEvents">
          <ClientEvent
            :key="event._id + 'e'"
            :event="event"
            @confirm="bookEventAction"
            @confirmFree="bookEventActionFree"
            @decline="declineEventAction"
          />
        </template>
      </v-card-text>

      <v-card-text v-else class="px-0">
        <v-alert text color="var(--v-grey-base)" class="font-size-12">
          <!-- {{
            $t(
                "There are no available slots for the selected date. Please choose another date or chat with your therapist to open a slot for you outside of these times"
            )
          }} -->
          {{ $t("There are no available slots for the selected date.") }}
          <br />

          {{ $t("Please choose another date or ") }}
          <v-btn
            color="primary"
            text
            small
            plain
            :ripple="false"
            class="px-0 fs-12px font-weight-normal lh-1"
            :to="{
              name: 'Room',
              params: {
                id:
                  me.rooms.find((r) => r.users[0]._id === this.myTherapist._id)
                    ._id || null,
              },
            }"
            >{{ $t("chat with your therapist") }}</v-btn
          >
          {{ $t("to open a slot for you outside of these times.") }}
        </v-alert>
      </v-card-text>
    </v-card>
  </v-col>
</template>

<script>
import AddLiveSession from "@/components/Billing/AddLiveSession";
import ClientEvent from "@/components/Calendar/ClientEvent";
import ClientNextSession from "@/components/Calendar/ClientNextSession";
import { mapActions } from "vuex";
import therapistMixin from "@/mixins/TherapistMixin";
import UserMixin from "@/mixins/UserMixin";

export default {
  name: "CalendarClientRightSide",
  components: {
    ClientEvent,
    ClientNextSession,
    AddLiveSession,
  },

  mixins: [therapistMixin, UserMixin],

  props: {
    clientUser: {
      required: true,
    },

    loading: {
      required: false,
      default: false,
    },

    // activeClientsCount: {
    //   required: true
    // },
    //
    // availableEvents: {
    //   required: true
    // },
    //
    // bookedEvents: {
    //   required: true
    // },
    //
    // eventDate: {
    //   required: true
    // },

    currentDateEvents: {
      required: true,
    },

    nextBookedEvent: {
      required: true,
    },
  },

  data: () => {
    return {
      addLiveSession: false,
      numberOfLiveSessions: 1,
    };
  },

  computed: {
    liveSessionCount() {
      return (
        (this.clientUser?.metadata?.numberLiveSessionsHave || 0) +
        (this.clientUser?.metadata?.numberCorporateLiveSessionsHave || 0)
      );
    },

    liveFreeSessionCount() {
      return this.clientUser?.metadata?.numberFreeLiveSessionsHave || "0";
    },
  },

  methods: {
    ...mapActions("subscription", ["createLiveSession"]),

    // async createLiveSessionAction() {
    //   this.$emit("update:loading", true)
    //
    //   let liveSessionActionRes = await this.createLiveSession({
    //     count: this.numberOfLiveSessions,
    //   });
    //
    //   this.$emit("update:loading", false)
    //
    //   window.location.replace(liveSessionActionRes?.url);
    // },

    onAddLiveSessionAction() {
      this.addLiveSession = true;
    },

    declineEventAction(event) {
      this.$emit("declineEventAction", event);
    },

    bookEventActionFree(event) {
      this.$emit("bookEventActionFree", event);
    },
    bookEventAction(event) {
      this.$emit("bookEventAction", event);
    },
  },
};
</script>

<style scoped></style>
