var render = function () {
var this$1 = this;
var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-col',[_c('v-card',{staticClass:"pa-5 mb-5",attrs:{"rounded":"lg","outlined":"","elevation":"0"}},[_c('p',{staticClass:"dark--text font-weight-medium"},[_vm._v(" "+_vm._s(_vm.$t("Live Session Credits:"))+" "),_c('strong',{staticClass:"font-weight-normal"},[_vm._v(" "+_vm._s(_vm.liveSessionCount)+" ")])]),_c('p',{staticClass:"dark--text font-weight-medium"},[_vm._v(" "+_vm._s(_vm.$t("Free session credit"))+": "),_c('strong',{staticClass:"font-weight-normal"},[_vm._v(" "+_vm._s(_vm.liveFreeSessionCount)+" ")])]),_c('v-btn',{staticClass:"mt-3 mb-5 py-5 body-2 font-weight-bold",attrs:{"color":"accent","outlined":"","small":"","block":"","loading":_vm.loading,"disabled":_vm.loading},on:{"click":_vm.onAddLiveSessionAction}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-plus-circle-outline")]),_vm._v(" "+_vm._s(_vm.$t("Buy additional Live Session credits"))+" ")],1),_c('AddLiveSession',{attrs:{"addLiveSession":_vm.addLiveSession,"loading":_vm.loading},on:{"update:addLiveSession":function($event){_vm.addLiveSession=$event},"update:add-live-session":function($event){_vm.addLiveSession=$event},"update:loading":function (val) {
          _vm.$emit('update:loading', val);
        }}})],1),_c('v-card',{staticClass:"pa-5 overflow-y-auto",staticStyle:{"max-height":"calc(100vh - 100px)"},attrs:{"rounded":"lg","outlined":"","elevation":"0"}},[(_vm.nextBookedEvent)?_c('p',{staticClass:"subtitle-2 mb-1 font-weight-light"},[_c('ClientNextSession',{attrs:{"nextBookedEvent":_vm.nextBookedEvent},on:{"decline":_vm.declineEventAction}})],1):_c('span',[_c('v-alert',{staticClass:"font-size-12",attrs:{"text":"","outlined":"","color":"primary","dense":""}},[_vm._v(" "+_vm._s(_vm.$t("NoBookedSessions"))+" ")])],1),_c('p',{staticClass:"subtitle-2 mb-1 font-weight-medium"},[_vm._v(" "+_vm._s(_vm.$t("LiveSessionAvailable"))+" ")]),(_vm.currentDateEvents.length > 0)?_c('v-card-text',{staticClass:"text-center px-0"},[_vm._l((_vm.currentDateEvents),function(event){return [_c('ClientEvent',{key:event._id + 'e',attrs:{"event":event},on:{"confirm":_vm.bookEventAction,"confirmFree":_vm.bookEventActionFree,"decline":_vm.declineEventAction}})]})],2):_c('v-card-text',{staticClass:"px-0"},[_c('v-alert',{staticClass:"font-size-12",attrs:{"text":"","color":"var(--v-grey-base)"}},[_vm._v(" "+_vm._s(_vm.$t("There are no available slots for the selected date."))+" "),_c('br'),_vm._v(" "+_vm._s(_vm.$t("Please choose another date or "))+" "),_c('v-btn',{staticClass:"px-0 fs-12px font-weight-normal lh-1",attrs:{"color":"primary","text":"","small":"","plain":"","ripple":false,"to":{
            name: 'Room',
            params: {
              id:
                _vm.me.rooms.find(function (r) { return r.users[0]._id === this$1.myTherapist._id; })
                  ._id || null,
            },
          }}},[_vm._v(_vm._s(_vm.$t("chat with your therapist")))]),_vm._v(" "+_vm._s(_vm.$t("to open a slot for you outside of these times."))+" ")],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }